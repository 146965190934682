<template>
  <div>
    <div
      v-if="is_running_test && evaluatee_view && can_answer && !finished_test"
      class="test-timer"
      :class="{ 'test-timer-sticky': window_top > 318 }"
    >
      <div v-if="window_top < 318">
        Tiempo Restante
        <Timer :start_date="test.start_time" :end_date="test.end_time" />
      </div>
      <div v-else>
        <Timer
          :start_date="test.start_time"
          :end_date="test.end_time"
          :format_visible="false"
          :flex_adjust="window_top > 318 ? true : false"
        >
          <template v-slot:flex_content>
            <div v-if="window_top > 318" class="questions-answered-sticky">
              <b-progress
                v-if="is_running_test && !finished_test"
                height="1.5em"
                variant="primary"
                :max="questionList.length"
              >
                <b-progress-bar variant="info" :value="countAnswer" animated>
                  <span
                    >Preguntas respondidas:
                    <strong
                      >{{ countAnswer }} / {{ questionList.length }}</strong
                    ></span
                  >
                </b-progress-bar>
              </b-progress>
            </div>
          </template>
        </Timer>
      </div>
    </div>
    <template
      v-else-if="evaluatee_view && can_answer && finished_test && !is_published"
    >
      <div class="time-label left-time-label">
        {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
        Finalizado (En espera de corrección)
      </div>
    </template>
    <template
      v-else-if="evaluatee_view && can_answer && finished_test && is_published"
    >
      <div class="time-label publish-blue-time-label">
        {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
        Corregido
      </div>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "EvaluateeTime",
  components: {
    Timer: () => import("@/components/reusable/Timer.vue"),
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
    evaluatee_view: {
      type: Boolean,
    },
    can_answer: {
      type: Boolean,
    },
    is_running_test: {
      type: Boolean,
    },
    finished_test: {
      type: Boolean,
    },
    is_published: {
      type: Boolean,
    },
  },
  data() {
    return {
      window_top: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      sections: names.SECTIONS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      evaluatee_redaction_questions: names.EVALUATEE_REDACTION_QUESTIONS,
      // filling_questions: names.NEW_FILLING_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      evaluatee_multiple_choice_questions:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTIONS,
      evaluatee_multiple_choice_question_options:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      evaluatee_matching_questions: names.EVALUATEE_MATCHING_QUESTIONS,
      evaluatee_matching_question_units:
        names.EVALUATEE_MATCHING_QUESTION_UNITS,
    }),
    redactionQuestions() {
      return this.redaction_questions.filter((x) => x.test == this.test.id);
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.test.id
      );
    },
    // fillingQuestions() {
    //   return this.filling_questions.filter((x) => x.test == this.test.id);
    // },
    matchingQuestions() {
      return this.matching_questions.filter((x) => x.test == this.test.id);
    },
    countAnswer() {
      let count_answer = 0;
      // redaction_questions
      const redaction_questions_ids = this.redactionQuestions.map((x) => x.id);
      count_answer += this.evaluatee_redaction_questions.filter(
        (x) =>
          x.answer != "" &&
          x.evaluatee == this.user.id &&
          redaction_questions_ids.includes(x.redaction_question)
      ).length;
      // multiple_choice_questions
      const multiple_choice_question_ids = this.multipleChoiceQuestions.map(
        (x) => x.id
      );
      this.evaluatee_multiple_choice_questions
        .filter(
          (x) =>
            multiple_choice_question_ids.includes(x.multiple_choice_question) &&
            x.evaluatee == this.user.id
        )
        .forEach((evaluatee_multiple_choice_question) => {
          if (
            this.evaluatee_multiple_choice_question_options.filter(
              (x) =>
                x.evaluatee_multiple_choice_question ==
                evaluatee_multiple_choice_question.id
            ).length > 0
          ) {
            count_answer++;
          }
        });
      // matching_questions
      const matching_question_ids = this.matchingQuestions.map((x) => x.id);
      this.evaluatee_matching_questions
        .filter(
          (x) =>
            matching_question_ids.includes(x.matching_question) &&
            x.evaluatee == this.user.id
        )
        .forEach((evaluatee_matching_question) => {
          if (
            this.evaluatee_matching_question_units.filter(
              (x) =>
                x.evaluatee_matching_question == evaluatee_matching_question.id
            ).length > 0
          ) {
            count_answer++;
          }
        });
      return count_answer;
    },
    questionList() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
        }))
      );
      return list.sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.time-label {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
}
.left-time-label {
  background-color: rgb(235, 70, 70);
}
.publish-blue-time-label {
  background-color: #007bff;
}
.questions-answered-sticky {
  margin-left: auto;
  margin-right: 0.3em;
  width: 75%;
}
.test-timer {
  width: 99.6%;
  margin-inline: 0.2%;
  border-radius: 5px;
  background-image: linear-gradient(
    to top right,
    var(--kl-menu-color),
    #116f72
  );
  color: white;
  font-weight: bold;
  transition: 0.2s;
}
.test-timer-sticky {
  width: 100%;
  background-image: linear-gradient(
    to top right,
    var(--kl-menu-color),
    #116f72
  );
  padding-top: 2em;
  transition: 0.2s;
  position: fixed;
  left: 0;
  top: 65px;
  z-index: 100;
}
@media (max-width: 1000px) {
  .questions-answered-sticky {
    width: 100% !important;
    margin-bottom: 0.1em;
  }
}
</style>

